import cloneDeep from 'lodash/cloneDeep';
import type { ISbSchema } from '@storyblok/vue';

export const getCustomizedSchemaForRichText = (schema: ISbSchema): ISbSchema => {
    //cloning the RichTextSchema to customise it, adding bullets to any unordered list (no type safety due to storyblok not providing proper imports for the types)
    // https://github.com/storyblok/storyblok-js-client/blob/v4/source/schema.js
    const customSchema = cloneDeep(schema);
    customSchema.nodes.bullet_list = () => {
        return {
            tag: [
                {
                    tag: 'ul',
                    attrs: {
                        class: 'flex flex-col gap-5 mb-5',
                        type: 'styled',
                    },
                },
            ],
        };
    };

    customSchema.nodes.list_item = (node: any) => {
        const listIcon = {
            type: 'image',
            attrs: {
                alt: 'circle',
                src: '/icons/check-circle-broken.svg',
                title: 'circle',
            },
        };

        if (node.content && node.content[0].content) {
            node.content.unshift(listIcon);
        }

        return {
            tag: [
                {
                    tag: 'li',
                    attrs: {
                        class: 'flex items-start gap-3 [&_img]:pt-2',
                        type: 'styled',
                    },
                },
            ],
        };
    };

    customSchema.nodes.ordered_list = () => {
        return {
            tag: [
                {
                    tag: 'ol',
                    attrs: {
                        class: 'flex list-decimal flex-col gap-5 list-inside mb-5',
                        type: 'styled',
                    },
                },
            ],
        };
    };

    customSchema.nodes.paragraph = (node: any) => {
        if (!node.content) {
            return {
                tag: [
                    {
                        tag: 'br',
                    },
                ],
            };
        }

        return {
            tag: [
                {
                    tag: 'p',
                    attrs: {
                        class: 'mb-5 last:mb-0',
                        type: 'styled',
                    },
                },
            ],
        };
    };

    customSchema.marks.textStyle = () => {
        return {
            tag: [
                {
                    tag: 'span',
                },
            ],
        };
    };

    customSchema.marks.link = (node: any) => {
        const attrs = { ...node.attrs };

        if (!node.attrs) return;

        const { linktype = 'url' } = node.attrs;

        if (attrs.anchor) {
            attrs.href = `${attrs.href}#${attrs.anchor}`;
            delete attrs.anchor;
        } else if (linktype === 'story') {
            attrs.href = getUrlFromStoryblokLink(node.attrs);
        } else if (linktype === 'asset') {
            attrs.target = '_blank';
            attrs.rel = 'noopener noreferrer';
        } else if (linktype === 'email') {
            attrs.href = `mailto:${attrs.href}`;
        }

        attrs.class = 'underline cursor-pointer';
        attrs.type = 'styled';

        return {
            tag: [
                {
                    tag: 'a',
                    attrs,
                },
            ],
        };
    };

    return customSchema;
};
